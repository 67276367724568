import HttpRequest from './HttpRequest'

class SpecialCaseProvider extends HttpRequest {
  findAll () {
    return this.get('/special-case')
  }

  findOne (id) {
    return this.get(`/special-case/${id}`)
  }

  create (payload) {
    return this.post('/special-case', payload)
  }

  update (id, payload) {
    return this.patch(`/special-case/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/special-case/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/special-case?page=${page}&limit=${limit}`, query)
  }
}

export default SpecialCaseProvider
